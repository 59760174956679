import styled from "styled-components";

export const Container = styled.div`
  background-color: #fd8421;
  display: flex;
  justify-content: center;
`;

export const Content = styled.div`
  display: flex;
  width: 96%;

  height: 4rem;
  justify-content: center;
  align-items: center;
  text-align: center;

  p {
    flex: 1;
    margin: 0;
    color: #fff;
    font-family: "Montserrat", sans-serif;
    font-weight: bold;
    font-size: 1rem;
  }
`;

export const BuyPerDepartament = styled.div`
  display: flex;
  width: 17%;

  color: #fff;

  align-items: center;
  justify-content: flex-start;
  border-right: 1px solid #fff;

  &:hover {
    cursor: pointer;
  }

  div {
    display: flex;
    align-items: center;

    p {
      margin-left: 0.3rem;
      user-select: none;
    }

    .seta {
      margin: 0 1em;
    }
  }

  @media (max-width: 1202px) {
    width: 25%;
  }

  @media (max-width: 1202px) {
    width: 30%;
  }

  @media (max-width: 1150px) {
    width: 20%;
  }

  @media(max-width: 930px) {
    flex: 1;
    border: none;

    div {
      margin-left: 5%;
    }
  }

`;

export const Departaments = styled.div`
  display: flex;
  width: 80%;

  @media(max-width: 930px) {
    display: none;
  }

  height: 4rem;
  align-items: center;
  text-align: center;

  div {
    width: 100%;
    margin: 0;
    height: 80%;
    display: flex;
    align-items: center;
    transition: background 500ms;

    p {
      color: #fff;
      font-family: "Montserrat", sans-serif;
      font-weight: bold;
      font-size: 1rem;
      border-right: 1px solid #fff;
      user-select: none;
    }

    @media(max-width: 1050px) {
      width: 90%;
    }
  }

  div:hover {
    cursor: pointer;
    background-color: rgba(255, 255, 255, 0.3);

    p {
      color: #248ee9;
    }
  }
`;

export const Dropdown = styled.div`
  position: absolute;
  z-index: 999;
  top: 205px;
  width: 16.8rem;
  transform: translateX(-12.5%);
  background-color: #fd8421;
  padding: 1rem;
  overflow-x: hidden;
  flex-direction: column;
  min-height: 35vh;
  max-height: 70vh;

  @media(max-width: 1300px) {
    width: 15rem;
  }

  @media(max-width: 1210px) {
    width: 14rem;
  }

  @media(max-width: 980px) {
    width: 13rem;
    transform: translateX(-10%);
  }

  @media(max-width: 940px) {
    width: 12.5rem;
    transform: translateX(-10%);
  }

  @media(max-width: 930px) {
    width: 101%;
    transform: translateX(-11.9%);
  }

  @media(max-width: 525px) {
    top: 304px;
    max-height: 60vh;
  }
`;

export const MenuItem = styled.a`
  height: 50px;
  width: 210px;
  transform: translateX(2%);
  display: flex;
  align-items: center;
  border-radius: 8px;
  transition: background 500ms;
  padding: 0.5rem;

  color: #fff;
  font-family: "Montserrat", sans-serif;
  font-weight: bold;
  font-size: 1rem;

  &:hover {
    background-color: rgba(255, 255, 255, 0.3);
  } 
`;