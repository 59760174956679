export const useLocalStorage = () => {

  const getStoragedItems = () => {
    const storagedItems = localStorage.getItem('@escola&cia:favorites')

    if(storagedItems) {
      const storaged = JSON.parse(storagedItems)
      return storaged
    }

    return []
  }

  const storageItem = (item: any) => {
    const storagedItems = getStoragedItems()

    storagedItems.push(item)

    localStorage.setItem('@escola&cia:favorites', JSON.stringify(storagedItems))

    return getStoragedItems()
  }

  const removeItem = (id: string) => {
    const storagedItems = getStoragedItems()

    console.log({storagedItems})

    const newStoragedItem = storagedItems.filter((item: any) => item.secure_id !== id)

    console.log('filter: ', {newStoragedItem})

    localStorage.setItem('@escola&cia:favorites', JSON.stringify(newStoragedItem))

    return getStoragedItems()
  }

  return {getStoragedItems, storageItem, removeItem}
}