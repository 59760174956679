import {  Image } from 'antd'
import { Content, ImageDiv, TextDiv } from './styles'
import { useLocalStorage } from '../../hooks/useLocalStorage'
import { Dispatch, SetStateAction } from 'react'
import { IProduct } from '../../interfaces'

export interface IVerticalListProps {
  data: IProduct[]
  setContent: Dispatch<SetStateAction<any[] | undefined>>
}

const VerticalProductList: React.FC<IVerticalListProps> = ({data: products, setContent: setState}) => {
  const { removeItem, getStoragedItems } = useLocalStorage() 

  const removeItemFromFavorite = (id: string) => {
    removeItem(id)
    setState(getStoragedItems())
  }

  return (
    <>
      { products.length > 0 ? products.map((product) => (
        <Content>
          <ImageDiv>
            <Image src={product.image_url} alt={product.name} />
          </ImageDiv>
          <TextDiv>
            <h2>{product.name}</h2>
            <p>{product.description}</p>
            <button onClick={() => {removeItemFromFavorite(product.secure_id)}} >Remover dos favoritos</button>
          </TextDiv>
        </Content>
      )): 'Não há produtos adicionados aos favoritos.'}
    </>
  )
}

export default VerticalProductList