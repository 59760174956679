import styled from "styled-components";

export const ContainerProd = styled.div`
  display: flex;
  background-color: #fd8421;
  border-bottom: 1px solid rgba(256, 256, 256, 0.4);
  height: 9rem;

  @media (max-width: 524px) {
    height: 15rem;
  }
`;

export const Imagediv = styled.div`
  width: 30%;

  @media (max-width: 810px) {
    width: 20%;
  }

  @media (max-width: 710px) {
    width: 20%;
  }

  @media (max-width: 524px) {
    width: 100%;
  }

`;

export const MenuDiv = styled.div`
  display: flex;
  width: 70%;

  @media (max-width: 810px) {
    width: 80%;
  }

  @media (max-width: 710px) {
    width: 80%;
  }

  @media (max-width: 524px) {
    display: none;
  }

`;

///

export const Container = styled.div`
  height: 15vh;
  width: 100%;
  background-color: #fd8421;

  border-bottom: 1px solid rgba(0, 0, 0, 0.3);

  display: grid;

  grid-template-areas: "logo searchBar nav";

  @media (max-width: 575px) {
    grid-template-areas:
      "logo nav"
      "searchBar searchBar";
  }
  @media (min-width: 576px) and (max-width: 767px) {
  }
  @media (min-width: 768px) and (max-width: 991px) {
  }
  @media (min-width: 992px) and (max-width: 1199px) {
  }
  @media (min-width: 1200px) {
  }
`;

export const ImageContainer = styled.div`
  grid-area: "logo";

  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;

  img {
    width: 53%;

    @media (max-width: 1078px) {
      width: 70%;
    }

    @media (max-width: 825px) {
      width: 90%;
    }

    @media (max-width: 710px) {
      width: 80%;
    }
  }

  &:hover {
    cursor: pointer;
  }
`;

export const SearchBarContainer = styled.div`
  grid-area: "searchBar";

  display: flex;
  justify-content: center;
  align-items: center;

  @media (max-width: 575px) {
    grid-column: searchBar;
  }
`;

export const SearchInput = styled.div`
  background-color: #fff;
  border: 1px solid #248eef;

  display: flex;
  justify-content: center;
  align-items: center;
  height: 40px;
  width: 80%;

  @media (max-width: 575px) {
    width: 60%;
  }

  input {
    width: 90%;
    border: none;
    outline: none;

    ::placeholder {
      color: #248eef;
    }
  }
`;

export const SearchButton = styled.div`
  background-color: #248eef;
  height: 40px;
  width: 20%;

  cursor: pointer;

  display: flex;
  justify-content: center;
  align-items: center;

  user-select: none;
  p {
    margin: 0;
    color: #fff;
  }
`;

export const NavContainer = styled.div`
  grid-area: "nav";

  max-width: 100%;
  margin: 0 auto;

  display: flex;
  justify-content: space-between;
  align-items: center;

  ul {
    margin: 0;
  }

  .content {
    width: 100%;
    display: flex;
    justify-content: center;
  }

  .logo a {
    color: #fff;
    text-decoration: none;
  }

  .menu {
    list-style: none;
    display: flex;
  }

  .menu li a {
    display: block;
    padding: 10px 15px;
    text-decoration: none;
    color: #fff;

    transition: 0.5s ease;

    font-family: "Montserrat", sans-serif;
    font-weight: bold;

    font-size: 1.2rem;

    @media (max-width: 825px) {
      font-size: 1.1rem;
    }

    @media (max-width: 670px) {
      font-size: 1rem;
    }

    @media (max-width: 617px) {
      font-size: 0.9rem;
    }

    @media (max-width: 570px) {
      font-size: 0.8rem;
    }
  }

  .menu li a:hover {
    color: #fff;
  }
`;
