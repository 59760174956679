import styled from 'styled-components';

export const Content = styled.div`
  display: flex;
  border-bottom: 1px solid #000;
  margin-bottom: 2rem;
`

export const ImageDiv = styled.div`
  img {
    width: 100%;
  }
`;

export const TextDiv = styled.div`
  h2 {
    margin-bottom: 0;
  }

  button {
    height: 35px;
    padding: 2px;
    
    border-color: #fd8421;
    background-color: #fd8421;

    cursor: pointer;
  }
`;