import styled from "styled-components";

interface ProductListTitleHeaderProps {
  hasMargin: boolean
}

export const Container = styled.div`
  padding: 42px;
`;

export const Products = styled.div`
  display: flex;
`;

export const Product = styled.div`
  margin: 0 1%;
  min-width: 200px;
  width: 20%;

  img {
    width: 100%;
  }

  @media (max-width: 575px) {
    min-width: 170px;
  }
`;

export const DemoAntd = styled.div`
  height: 300px;
  padding: 8px 24px;
  overflow: auto;
  border: 1px solid #e8e8e8;
  border-radius: 4px;
`;

export const DemoReact = styled.div`
  /* height: 300px; */
  width: 100%;
  padding: 8px 24px;
  overflow: auto;
  border: 1px solid #e8e8e8;
  border-radius: 4px;
`;

export const Button = styled.button`
  background-color: #fd8421;
  margin-top: 0.3rem;
  height: 40px;

  border: none;
  padding: 16px;
  border-radius: 5px;

  cursor: pointer;

  display: flex;
  justify-content: center;
  align-items: center;

  user-select: none;

  color: #fff;
`

export const ProductListTitleDiv = styled.div`
  display: inline-block;
  
  &&:hover {
    cursor: pointer;

    h1, span {
      color: #fd8421;
    }
  }
`

export const ProductListTitleHeader = styled.h1<ProductListTitleHeaderProps>`
  margin-bottom: ${props => props.hasMargin ? '-8px' : '0px' };
`