import React, { useState, useEffect } from "react";

import { Container, Content, BuyPerDepartament, Departaments, Dropdown, MenuItem } from "./styles";
import { UnorderedListOutlined, DownOutlined, UpOutlined } from "@ant-design/icons";
import { useHistory } from "react-router-dom";
import useAxios from 'axios-hooks'
import { IDepartament } from "../../interfaces";

const DesktopSubMenu: React.FC = () => {
  const [open, setOpen] = useState(false)
  const [departaments, setDepartaments] = useState<IDepartament[]>([])
  const history = useHistory();

  const [{loading: loadingGetDepartaments}, getDepartaments] = useAxios({
    method:'GET',
    url: `${process.env.REACT_APP_API_URL}/departaments`,
  }, {manual: true})


  const getData = async () => {
    const response = await getDepartaments()

    setDepartaments(response.data)
  }

  useEffect(() => {
    getData()
  }, [])

  function redirect(target: string) {
    history.push(target);
  }

  const DropdownMenu = () => {
    const DropdownItem  = (props: any) => {
      return (
        <MenuItem onClick={() => redirect(props.to)} >
          <span className='icon-buttom'>{props.leftIcon}</span>
          {props.children}

          <span className='icon-right'>{props.rightIcon}</span>
        </MenuItem>
      )
    }

    return (
      <Dropdown>
        {departaments && departaments.map(departament => (
          <DropdownItem to={`/departament/${departament.secure_id}`}>{departament.name}</DropdownItem>
        ))}
      </Dropdown>
    )
  }

  return (
    <Container>
      <Content>
        <BuyPerDepartament onClick={() => setOpen(!open)}>
          <div>
            <UnorderedListOutlined />
            <p>Departamentos</p>

           
           {open ? (<UpOutlined className='seta' />) : <DownOutlined className='seta' /> } 

            {open && (<DropdownMenu />)}
          </div>
        </BuyPerDepartament>
        <Departaments>
        {departaments && departaments.slice(0, 5).map(departament => (
          <div>
            <p onClick={() => redirect(`/departament/${departament.secure_id}`)}>{departament.name}</p>
          </div>
        ))}
        </Departaments>
      </Content>
    </Container>
  );
};

export default DesktopSubMenu;
