import React, { useRef } from "react";
import { useHistory } from "react-router-dom";
import {
  Container,
  ImageContainer,
  NavContainer,
  SearchBarContainer,
  SearchInput,
  SearchButton,
  ContainerProd,
  Imagediv,
  MenuDiv
} from "./styles";

import Logo from "../../logo.svg";
import LogoHorizontal from '../../logo-horizontal.svg'

import {
  UnorderedListOutlined,
  HomeOutlined,
  UserOutlined,
  LoginOutlined,
  HeartOutlined,
  MailOutlined,
  WhatsAppOutlined,
} from "@ant-design/icons";

import { useLocalStorage } from '../../hooks/useLocalStorage'

import LoginModal, { IModalHandles } from '../Login'
import Drawer, { IDrawerHandles } from '../Drawer'
import VerticalProductList from "../VerticalProductsList";
import { WhatappButton } from "../WhatsappButton";

const Header: React.FC = () => {
  const {getStoragedItems} = useLocalStorage()
  const { push } = useHistory();
  const modalRef = useRef<IModalHandles>(null)
  const drawerRef = useRef<IDrawerHandles>(null)

  const drawerFooter = () => (
    <div style={{ display: 'flex', flexDirection:'column', justifyContent:'space-evenly', height:'6rem' }} >
      <button style={{height: '35px',  padding: '2px', backgroundColor:'#fd8421'}} >
        Enviar Favoritos Por Email &nbsp; <MailOutlined />
      </button>
  
      <WhatappButton number="75991407322" message={getStoragedItems()} >
        Enviar Favoritos Por WhatsApp &nbsp; <WhatsAppOutlined />
      </WhatappButton>
    </div>
  )

  return (
    <ContainerProd>
      <Imagediv>
        <ImageContainer
          onClick={() => {
            push("/");
          }}
        >
          <img src={Logo} alt="Logo Episteme" />
        </ImageContainer>
      </Imagediv>

      <MenuDiv>
        <NavContainer>
            <ul className="menu active">
              <li>
                <a href="#">
                  <HomeOutlined size={20} /> Sobre Nós
                </a>
              </li>
              <li onClick={() => {drawerRef.current?.handleOpenDrawer({title: 'Favoritos'})}}>
                <a href="#">
                  <HeartOutlined size={20} /> Favoritos
                </a>
              </li>
              <li onClick={() => modalRef.current?.handleOpenModal(false)} >
                <a href="#">
                  <UserOutlined />
                  Login
                </a>
              </li>
              <li onClick={() => modalRef.current?.handleOpenModal(true)} >
                <a href="#">
                  <LoginOutlined /> Registre-se
                </a>
              </li>
            </ul>
        </NavContainer>
      </MenuDiv>
      <LoginModal ref={modalRef} />
      <Drawer ref={drawerRef} footer={drawerFooter()} placement='left' width='500px' function={getStoragedItems} />
    </ContainerProd>
    // <Container>
      // <ImageContainer
      //   onClick={() => {
      //     push("/");
      //   }}
      // >
      //   <img src={Logo} alt="Logo Episteme" />
      // </ImageContainer>

      // {window.innerWidth > 500 ? (
        // <>
          // <SearchBarContainer>
          //   <SearchInput>
          //     <input type="text" placeholder="Procurando por algo ?" />
          //   </SearchInput>
          //   <SearchButton>
          //     <p>
          //       <strong>Buscar</strong>
          //     </p>
          //   </SearchButton>
          // </SearchBarContainer>

          // <NavContainer>
          //   <div className="hamburguer active"></div>
          //   <ul className="menu active">
          //     <li>
          //       <a href="#">
          //         <HomeOutlined size={20} /> Sobre Nós
          //       </a>
          //     </li>
          //     <li>
          //       <a href="#">
          //         <HeartOutlined size={20} /> Favoritos
          //       </a>
          //     </li>
          //     <li onClick={() => modalRef.current?.handleOpenModal(false)} >
          //       <a href="#">
          //         <UserOutlined />
          //         Login
          //       </a>
          //     </li>
          //     <li onClick={() => modalRef.current?.handleOpenModal(true)} >
          //       <a href="#">
          //         <LoginOutlined /> Registre-se
          //       </a>
          //     </li>
          //   </ul>
          // </NavContainer>
        // </>
      // ) : (
        // <>
          // <NavContainer>
          //   <HomeOutlined style={{ fontSize: "2rem" }} />
          //   <UnorderedListOutlined style={{ fontSize: "2rem" }} />
          // </NavContainer>

          // <SearchBarContainer>
          //   <SearchInput>
          //     <input type="text" placeholder="Procurando por algo ?" />
          //   </SearchInput>
          //   <SearchButton>
          //     <p>
          //       <strong>Buscar</strong>
          //     </p>
          //   </SearchButton>
          // </SearchBarContainer>
        // </>
      // )}
    // </Container>
  );
};

export default Header;
