import styled from "styled-components";

export const Container = styled.div`
  padding: 80px;
  p {
    font-size: 1.1rem;
  }
`;

export const Content = styled.div`
  padding: 16px;

  display: flex;
  flex-wrap: wrap;
`;

export const Product = styled.div`
  margin: 1%;
  min-width: 250px;
  /* width: 14%; */
  flex: 1;

  img {
    width: 100%;
  }

  @media (max-width: 575px) {
    min-width: 170px;
  }
`;
