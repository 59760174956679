import React, {useRef} from "react";
import { 
  Section, 
  ImageContainer, 
  ContactsContainer, 
  AboutUsContainer,
  FooterItems,
  Item
} from "./styles";

import { useHistory } from 'react-router-dom'

import {HomeOutlined, HeartOutlined, UserOutlined, LoginOutlined, MailOutlined, WhatsAppOutlined} from '@ant-design/icons'

import LoginModal, {IModalHandles } from '../Login'

import LogoFooter from "../../logo-footer.svg"
import LogoFooter2 from "../../logo-footer-2.svg"

import Drawer, { IDrawerHandles } from '../Drawer/index'
import { useLocalStorage } from '../../hooks/useLocalStorage'
import VerticalProductList from "../VerticalProductsList";

const Footer: React.FC = () => {
  const modalRef = useRef<IModalHandles>(null)
  const drawerRef = useRef<IDrawerHandles>(null)

  const {push} = useHistory()
  const {getStoragedItems} = useLocalStorage()

  const drawerFooter = () => (
    <div style={{ display: 'flex', flexDirection:'column', justifyContent:'space-evenly', height:'6rem' }} >
      <button style={{height: '35px',  padding: '2px', backgroundColor:'#fd8421'}} >
        Enviar Favoritos Por Email &nbsp; <MailOutlined />
      </button>
  
      <button style={{height: '35px',  padding: '2px', backgroundColor:'#fd8421'}} >
        Enviar Favoritos Por WhatsApp &nbsp; <WhatsAppOutlined />
      </button>
    </div>
  )

  return (
    <>
      <LoginModal ref={modalRef} />
      <Drawer ref={drawerRef} footer={drawerFooter()} placement='left' width='350px' function={getStoragedItems} />
      <Section >
        <ImageContainer>
          <img src={LogoFooter} alt='' />
        </ImageContainer>
        <ContactsContainer>
          <div>
            <h1>Fale Conosco</h1>
            <p>(75) 00000-0000</p>
            <p>episteme@casadaescola.com</p>
            <h1>Encontre-nos</h1>
            <p>Rua do papagaio, 888, Centro, <br/> Shopping Boulevard</p>
            <span>Feira de Santana - BA, 44444-444</span>
          </div>
        </ContactsContainer>
        <AboutUsContainer>
          <div>
            <h1>Sobre Nós</h1>
            <p>Lorem ipsum dolor sit amet, consec-tetuer adipiscing elit, sed diam no-nummy nibh euismod tincidunt ut laoreet dolore magna aliquam erat volutpat. Ut wisi enim ad minim veniam, quis nostrud exerci tation ullamcorper suscipit lobortis nisl ut aliquip ex ea commodo consequat. Duis autem vel eum iriure dolor in hendrerit in vulputate velit esse mo-</p>
            {/* <img src={LogoFooter2} alt='' /> */}
          </div>
        </AboutUsContainer>
      </Section>

      <FooterItems>
        <Item>
          <div onClick={() => push('/')} >
            <HomeOutlined style={{ fontSize: "1.5rem" }} />
            <p>Home</p>
          </div>
        </Item>
        <Item>
          <div onClick={() => {drawerRef.current?.handleOpenDrawer({title:'Favoritos'})}}>
            <HeartOutlined style={{ fontSize: "1.5rem" }} />
            <p>Favoritos</p>
          </div>
        </Item>
        <Item>
          <div onClick={() => modalRef.current?.handleOpenModal(false)} >
            <UserOutlined style={{ fontSize: "1.5rem" }} />
            <p>Login</p>
          </div>
        </Item>
        <Item>
          <div onClick={() => modalRef.current?.handleOpenModal(true)} >  
            <LoginOutlined style={{ fontSize: "1.5rem" }} />
            <p>Registre-se</p>
          </div>
        </Item>
      </FooterItems>
    </>
  );
};

export default Footer;
