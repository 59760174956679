import styled from "styled-components";

export const Container = styled.div`
  height: 100%;
  width: 100%;

  display: flex;
  justify-content: space-around;

  padding: 16px;

  flex-wrap: wrap;

  @media (max-width: 880px) {
    display: block;
  }
`;

export const MainImage = styled.div`
  height: 100%;
  flex: 0.65;

  min-width: 500px;

  img {
    height: 100%;
    width: 100%;
  }

  @media (max-width: 880px) {
    min-width: 500px;
  }

  @media (max-width: 575px) {
    flex: 1;
    min-width: 100px;
    min-height: 10px;
  }
`;

export const SideImages = styled.div`
  flex: 0.25;

  display: flex;
  flex-direction: column;
  justify-content: space-between;

  .top {
    flex: 0.49;

    img {
      height: 100%;
      width: 100%;
    }
  }

  .bottom {
    flex: 0.49;

    img {
      height: 100%;
      width: 100%;
    }
  }

  @media (max-width: 880px) {
    flex-direction: row;
    margin-top: 2rem;
  }
`;
