import React from "react";
import "antd/dist/antd.css";
import { BrowserRouter, Switch, Route } from "react-router-dom";

import Home from "./pages/Home";
import Departament from "./pages/Departament";

const App: React.FC = () => {
  return (
    <BrowserRouter>
      <Switch>
        <Route exact path="/">
          <Home />
        </Route>
        <Route exact path="/departament/:id">
          <Departament />
        </Route>
      </Switch>
    </BrowserRouter>
  );
};

export default App;
