import React, { useEffect, useRef, useState } from "react";

import { Container, Products, Product, DemoReact, Button, ProductListTitleDiv, ProductListTitleHeader } from "./styles";
import { Image } from "antd";
import { useLocalStorage } from '../../hooks/useLocalStorage'

import ChairWithoutBackground from "../../assets/cadeira-sem-fundo.png";
import Chair from "../../assets/cadeira-fundo.png";

import Modal, {IModalHandles} from '../Modal'

import { HeartFilled, HeartOutlined } from '@ant-design/icons'
import { IProduct } from "../../interfaces";
import {useHistory} from 'react-router-dom'
interface IProductsList {
  listTitle: string;
  data?: IProduct[]
  secureId?: string
}

const products = [
  {
    id: 1,
    name: 'Mochila',
    description: 'Mochila de tecido com camuflagem do exército',
    imageUrl: 'https://beta.apinouthemes.com/uploads/1e1f0f78e33d4ce7828d21465e84da7d.jpg'
  },
  {
    id: 2,
    name: 'Mochila',
    description: 'Mochila de tecido com camuflagem do exército',
    imageUrl: 'https://beta.apinouthemes.com/uploads/1e1f0f78e33d4ce7828d21465e84da7d.jpg'
  },
  {
    id: 3,
    name: 'Mochila',
    description: 'Mochila de tecido com camuflagem do exército',
    imageUrl: 'https://beta.apinouthemes.com/uploads/1e1f0f78e33d4ce7828d21465e84da7d.jpg'
  },
  {
    id: 4,
    name: 'Mochila',
    description: 'Mochila de tecido com camuflagem do exército',
    imageUrl: 'https://beta.apinouthemes.com/uploads/1e1f0f78e33d4ce7828d21465e84da7d.jpg'
  },
  {
    id: 5,
    name: 'Mochila',
    description: 'Mochila de tecido com camuflagem do exército',
    imageUrl: 'https://beta.apinouthemes.com/uploads/1e1f0f78e33d4ce7828d21465e84da7d.jpg'
  },
  {
    id: 6,
    name: 'Mochila',
    description: 'Mochila de tecido com camuflagem do exército',
    imageUrl: 'https://beta.apinouthemes.com/uploads/1e1f0f78e33d4ce7828d21465e84da7d.jpg'
  },
  {
    id: 7,
    name: 'Mochila',
    description: 'Mochila de tecido com camuflagem do exército',
    imageUrl: 'https://beta.apinouthemes.com/uploads/1e1f0f78e33d4ce7828d21465e84da7d.jpg'
  },
  {
    id: 8,
    name: 'Mochila',
    description: 'Mochila de tecido com camuflagem do exército',
    imageUrl: 'https://beta.apinouthemes.com/uploads/1e1f0f78e33d4ce7828d21465e84da7d.jpg'
  },
  {
    id: 9,
    name: 'Mochila',
    description: 'Mochila de tecido com camuflagem do exército',
    imageUrl: 'https://beta.apinouthemes.com/uploads/1e1f0f78e33d4ce7828d21465e84da7d.jpg'
  },
]

const ProductsList: React.FC<IProductsList> = ({ listTitle, data, secureId }) => {
  const history = useHistory()
  const {storageItem} = useLocalStorage()

  const modalRef = useRef<IModalHandles>(null)

  function redirect(target: string) {
    history.push(target);
  }

  return (
    <Container>
      <ProductListTitleDiv onClick={() => secureId ? redirect(`/departament/${secureId}`) : null} >
        <ProductListTitleHeader hasMargin={secureId ? true : false}>{listTitle}</ProductListTitleHeader>
        {/* {secureId && (<span> Clique para ver mais </span>)} */}
      </ProductListTitleDiv>
      <hr />
      <DemoReact>
        <Products>
        {!data ? products.map((product: any) => (
            <Product>
            {" "}
            <Image src={product.imageUrl} alt="" />
            <h3>{product.name} </h3>
            <hr />
            <Button onClick={() => modalRef.current?.handleOpenModal({title: product.name, content: product.description})}>Clique aqui para ver mais</Button>
           </Product>
          )) : data.map(product => (
            <Product>
            {" "}
            <Image src={product.image_url} alt="" />
            <h3>{product.name} </h3>
            <hr />
            <Button onClick={() => modalRef.current?.handleOpenModal({title: product.name, content: product.description})}>Clique aqui para ver mais</Button>
           </Product>
          ))}
        </Products>
      </DemoReact>
      <Modal ref={modalRef} />
    </Container>
  );
};

export default ProductsList;
