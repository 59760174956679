import React, { useState, useImperativeHandle, forwardRef } from 'react';
import { Modal as AntModal } from 'antd';

export interface IModalHandles {
  handleOpenModal: (params: IOpenedModalProps) => void
  handleCloseModal: () => void
}

interface IOpenedModalProps {
  title: string
  content: string
}

const Modal: React.ForwardRefRenderFunction<IModalHandles> = (_, ref) => {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [title, setTitle] = useState('')
  const [content, setContent] = useState('')

  useImperativeHandle(ref, () => ({
    handleOpenModal,
    handleCloseModal
  }))

  const handleOpenModal = ({title, content}: IOpenedModalProps) => {
    setTitle(title)
    setContent(content)
    setIsModalVisible(true);
  };

  const handleCloseModal = () => {
    setIsModalVisible(false);
  };


  return (
      <AntModal 
        title={title}
        visible={isModalVisible}
        onCancel={handleCloseModal}
        footer=''
      >
        {content}
      </AntModal>
  );
};
export default forwardRef(Modal);