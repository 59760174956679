import React, { ReactNode } from 'react'

interface IWhatsappButtonProps {
  number: string
  message?: any
  children?: ReactNode 
}

const URL = 'https://wa.me';

export const WhatappButton: React.FC<IWhatsappButtonProps> = ({number, message, children}) => {

  number = number.replace(/[^\w\s]/gi, '').replace(/ /g, '');


  let url = `${URL}/${number}`;
  let teste = '';

  if (message) {
    for (const msg of message) {
      teste += `${msg.name}\n`
    }
    url += `?text=${encodeURI(teste)}`;
  }

  const openWhats = () => {
    window.open(url)
  }

  return (
    <button style={{height: '35px',  padding: '2px', backgroundColor:'#fd8421'}} onClick={() => openWhats()} >
      {children}
    </button>
  )
}