import styled from "styled-components";

export const Section = styled.section`
  display: flex;
  background-color: #248eef;

  flex-wrap: wrap;
`;

export const ImageContainer = styled.div`
  flex: 1;
  display: flex;
  background-color: #fff;
  height: 35rem;
  align-items: center;
  border-top-right-radius: 100rem;
  border-bottom-right-radius: 100rem;

  min-width: 260px;
`;

export const ContactsContainer = styled.div`
  flex: 1;
  display: flex;
  justify-content: center;
  padding: 3.5rem 0;
  min-width: 260px;

  div {
    h1 {
      color: #fd8421;
    }

    p, span {
      color: #fff;
    }
  }

  @media(max-width: 779px) {
    align-items: center;
  }
`;

export const AboutUsContainer = styled.div`
  flex: 1;
  padding: 3.5rem 0;
  min-width: 260px;
  display: flex;

  div {
    h1 {
      color: #fd8421;
    }

    p {
      color: #fff
    }

    img {
      width: 100%;
    }
  }

  @media(max-width: 779px) {
    padding: 16px;

    div {
      img {
        width: 80%;
        margin-left: 3rem;
      }
    }
  }

  @media(max-width: 630px) {
    div {
      img {
        margin-left: 2rem;
      }
    }
  }

  @media(max-width: 524px) {
    margin-bottom: 8rem;
  }

  @media(max-width: 400px) {
    div {
      img {
        margin-left: 1rem;
      }
    }
  }
`;

export const FooterItems = styled.div`
  display: none;
  border-top: 1px solid #000;
  background-color: #fd8421;
  height: 70px;
  width: 100%;
  bottom: 0;
  position: fixed;
  justify-content: space-around;
  z-index: 999;


  p {
    user-select: none;
  }

  @media (max-width: 524px) {
    display: flex;
  }
`;

export const Item = styled.div`
  width: 20%;
  height: 100%;
  display: flex;
  justify-content: center;
  text-align: center;
  align-items: center;
  color: #fff;
  p {
    margin: 0;
  }
`;