import React, {useEffect, useState} from "react";

import Header from "../../components/Header";
import DesktopSubMenu from "../../components/DesktopSubMenu";
import Banner from "../../components/Banner";
import Advantages from "../../components/Advantages";
import ProductsList from "../../components/ProductsList";
import Footer from "../../components/Footer";
import { Carousel, Image, Spin } from "antd";
import useAxios from 'axios-hooks'

import Banner1 from "../../assets/banner-1.png";
import Banner2 from "../../assets/banner-2.png";
import Banner3 from "../../assets/banner-3.png";

import {IDepartament} from '../../interfaces' 
import { toCapitalizerCase } from '../../helpers/toCapitalizerCase'

const Home: React.FC = () => {

  const [departaments, setDepartaments] = useState<IDepartament[]>([])

  useEffect(() => {
    getData()
  }, [])

  async function getData() {
    const {data} = await getDepartaments()
    setDepartaments(data)
  }

  const contentStyle = {
    height: "160px",
    color: "#fff",
    lineHeight: "160px",
    textAlign: "center",
    background: "#364d79",
  };

  const [{loading: loadingGetDepartaments}, getDepartaments] = useAxios({
    method:'GET',
    url: `${process.env.REACT_APP_API_URL}/departaments`,
  }, {manual: true})

  return (
    <>
      <Spin spinning={loadingGetDepartaments} tip="Obtendo dados..." >
        <Header />
        <DesktopSubMenu />
        <Banner />
        {/* <Advantages /> */}
        {/* <ProductsList listTitle="Em Destaque" data={departaments.map(departament => departament.products[0])} /> */}
        {/* <Banner /> */}
        {departaments.length > 0 && departaments.map(departament => (
          <ProductsList listTitle={toCapitalizerCase(departament.name)} data={departament.products.slice(0, 5)} secureId={departament.secure_id} />
        ))}
        <Footer />
      </Spin>
    </>
  );
};

export default Home;
