import React, { useState, useImperativeHandle, forwardRef } from 'react';
import { Drawer as AntDrawer, DrawerProps } from 'antd';

import VerticalProductList from '../VerticalProductsList';

export interface IDrawerHandles {
  handleOpenDrawer: (params: IOpenedDrawerProps) => void
  handleCloseDrawer: () => void
}

export interface IDrawerProps {
  placement: DrawerProps['placement']
  footer: React.ReactNode
  width: DrawerProps['width']
  function: () => any[]
}

interface IOpenedDrawerProps {
  title: string
}

const Drawer: React.ForwardRefRenderFunction<IDrawerHandles, IDrawerProps> = ({placement, footer, width, ...rest}, ref) => {
  const [isDrawerVisible, setIsDrawerVisible] = useState(false);
  const [title, setTitle] = useState('')
  const [data, setData] = useState<any[]>()
  const {function: loadData} = rest

  useImperativeHandle(ref, () => ({
    handleOpenDrawer,
    handleCloseDrawer
  }))

  const handleOpenDrawer = ({title}: IOpenedDrawerProps) => {
    setTitle(title)
    setIsDrawerVisible(true);
    const content = loadData()
    setData(content)
  };

  const handleCloseDrawer = () => {
    setIsDrawerVisible(false);
  };

  return (
      <AntDrawer 
        title={title}
        visible={isDrawerVisible}
        placement={placement}
        onClose={handleCloseDrawer}
        footer={footer}
        width={width}
      >
        <VerticalProductList setContent={setData} data={data ?? []} />
      </AntDrawer>
  );
};
export default forwardRef(Drawer);