import styled from 'styled-components'

export const Content = styled.div`
  form {
    display: flex;
    flex-direction: column;

    input {
      height: 40px;
      margin-bottom: 2rem;
      padding: 16px;
      border: 1px solid #fd8421;
    }

    button {
      height: 40px;
      background-color: #fd8421;
      border: none;
      cursor: pointer;
      margin-bottom: 2rem;

      strong {
        color: #000;
      }
    }

    p {
      user-select: none;
    }

    strong {
      cursor: pointer;
      color: #fd8421;
    }
  }
`;