import React, {useState, useEffect} from "react";
import { Image, Spin } from "antd";
import { Container, Content, Product } from "./styles";
import { useParams } from "react-router-dom";
import useAxios from 'axios-hooks'
import { useLocalStorage } from '../../hooks/useLocalStorage'

import Header from "../../components/Header";
import DesktopSubMenu from "../../components/DesktopSubMenu";
import Footer from "../../components/Footer";
import { IProduct } from "../../interfaces";
import { HeartFilled } from "@ant-design/icons";

const Departament: React.FC = () => {

  const [products, setProducts] = useState<IProduct[]>()
  const [title, setTitle] = useState<string>('')

  const { id } = useParams<{
    id: string
  }>(); 

  const { storageItem } = useLocalStorage()


  const [{loading: loadingGetDepartament}, getDepartament] = useAxios({
    method:'GET',
    url: `${process.env.REACT_APP_API_URL}/departaments/${id}`,
  }, {manual: true})


  const getData = async () => {
    const {data} = await getDepartament()

    setProducts(data.products)
    setTitle(data.name)
  }

  useEffect(() => {
    getData()
  }, [id])

  return (
    <div>
      <Spin spinning={loadingGetDepartament} tip="Buscando Daddos..." >
        <Header />
        <DesktopSubMenu />
        <Container>
          <h1>{title}</h1>
          <p>
            Existem diversos aparelhos, como esteiras e bicicletas, voltados para
            o uso residencial. Isso significa que eles são desenhados para
            suportar um desgaste muito menor. Sendo assim, se você colocá-los em
            seu espaço fitness, em que o uso é intenso, a chance é que eles não
            resistem por muito tempo.
          </p>
          <hr />
          <Content>
            {products && products.map(product => (
              <Product key={product.image_key}>
                <Image src={product.image_url} alt="" />
                <h3>{product.name} <HeartFilled onClick={() => storageItem(product)} /> </h3>
                <hr />
                <h4>{product.description}</h4>
              </Product>
            ))}
          </Content>
        </Container>
        <Footer />
      </Spin>
    </div>
  );
};

export default Departament;
