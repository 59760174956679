import React from "react";
import { Container, MainImage, SideImages } from "./styles";
import { Carousel, Image } from "antd";

import BannerImage from "../../assets/banner-aulas.png";
import Banner1 from "../../assets/banner-1.png";
import Banner2 from "../../assets/banner-2.png";
import Banner3 from "../../assets/banner-3.png";

const Banner: React.FC = () => {
  return (
    <Container>
      <MainImage>
        {/* <img src="https://wallpaperaccess.com/full/1496231.jpg" alt="" /> */}
        <Carousel effect="fade">
          <div>
            <Image src={Banner1} alt="" />
          </div>
        </Carousel>
      </MainImage>
      <SideImages>
        <div className="top">
          {" "}
          <Image src={Banner2} alt="" />
        </div>
        <div className="bottom">
          {" "}
          <Image src={Banner3} alt="" />
        </div>
      </SideImages>
    </Container>
  );
};

export default Banner;
