import React, { useState, useImperativeHandle, forwardRef } from 'react';
import { Modal as AntModal } from 'antd';
import { Content } from './style'

export interface IModalHandles {
  handleOpenModal: (isSignUp: boolean) => void
  handleCloseModal: () => void
}

const LoginModal: React.ForwardRefRenderFunction<IModalHandles> = (_, ref) => {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isSignUp, setIsSignUp] = useState(true);

  useImperativeHandle(ref, () => ({
    handleOpenModal,
    handleCloseModal
  }))

  const handleOpenModal = (isSignUp: boolean) => {
    setIsModalVisible(true);
    setIsSignUp(isSignUp)
  };

  const handleCloseModal = () => {
    setIsModalVisible(false);
  };


  return (
      <AntModal 
        title={ isSignUp ? 'Criar Conta' : 'Login do Cliente' }
        visible={isModalVisible}
        onCancel={handleCloseModal}
        footer=''
      >
        <Content>

          <form action="">
            {isSignUp && (
              <>
                <label htmlFor="name">Nome</label>
                <input type="text" id="name" placeholder="ex: João da Silva" required/>
              </>
            )}

            <label htmlFor="email">Email</label>
            <input type="email" id="email" placeholder="ex: joaodasilva@gmail.com" required/>

            <label htmlFor="password">Password</label>
            <input type="password" id="password" placeholder="senha" required/>

            {isSignUp && (
              <>
                <label htmlFor="phone">Número para contato</label>
                <input type="text" id="phone" placeholder="(75) 3223-7677 | (71) 99999-9999" required/>
              </>
            )}
                
            <button> <strong>{isSignUp ? 'Registrar-se' : 'Continuar'}</strong></button>
            <p> {isSignUp ? 'Já tem uma conta ?' : 'Ainda não tem uma conta ? '} <strong onClick={() => setIsSignUp(!isSignUp)} > {isSignUp ? 'Faça Login!' : 'Registre-se!' } </strong> </p>
          </form>
        </Content>
      </AntModal>
  );
};
export default forwardRef(LoginModal);